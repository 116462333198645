import React from "react"
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import ContactPreview from "../content/contacts/ContactPreview"
import Text from "../content/texts/Text"

class ContactUs extends React.Component {
  constructor(props) {
    super(props)
  }

  contacts() {
    return this.props.data.contacts.edges.map((contact, index) => (
      <div key={index} className="col-12 col-md-6 col-lg-3 col-xl-3 mb-md-5">
        <ContactPreview contact={contact} details={true} />
        <hr className="d-block d-md-none" />
      </div>
    ))
  }

  offices() {
    let officeName, jobEmailId
    return this.props.data.offices.edges.map((office, index) => {
      officeName = office.node.officeName
      jobEmailId = ``
      office.node.contacts.map((jobEmail, index) => {
        if (
          jobEmail.contactType.name === `Job Opportunities` ||
          jobEmail.contactType.name === `Jobs`
        ) {
          jobEmailId = jobEmail.contactEmail
        }
      })
      return (
        <article key={index} className={`office__address col-12 col-md-4 mb-3`}>
          <div className="row">
            <aside className="col-9 col-sm-10 office__address__details">
              <h3 className="office__name">{officeName}</h3>
              <a href={`mailto:${jobEmailId}`}>{jobEmailId}</a>
            </aside>
          </div>
        </article>
      )
    })
  }

  render() {
    return (
        <Layout>
        <SEO 
          title={`Let's talk`} 
          description={`Call now! Our operators are standing by.`}
          seoFields={this.props.data.seoFields}
        />
            <section className="contact_page container-fluid">
                <header className="single__header">
                <p className="h4 text-center">Contact</p>
                </header>

                <header className="row contact_page__header mb-3">
                <aside className="col-12 col-lg-6">
                    <h1 className="h1">Now we’re talking</h1>
                </aside>

                {/* intro text */}
                {this.props.data.contactIntro && (
                    <aside className="contact_page__intro_text col-12 col-lg-6 mb-5 entry">
                    <Text text={this.props.data.contactIntro} container={false} />
                    </aside>
                )}
                </header>

                {/* contacts */}
                <div className="row">{this.props.data.contacts && this.contacts()}</div>

                {/* careers */}

                <div className="row mb-2 mb-xl-5">
                <div className="col-12 mb-4 d-none d-md-block">
                    <hr className="sep" />
                </div>

                <aside className="col-12 col-lg-6">
                    <h2 className={`h1`}>Job Opportunities</h2>
                </aside>

                {/* intro text */}
                {this.props.data.contactCareer && (
                    <aside className="contact_page__intro_text col-12 col-lg-6 mb-5 entry">
                    <Text text={this.props.data.contactCareer} container={false} />
                    <div className="row mt-3 contact_page__offices">
                        {this.offices()}
                    </div>
                    </aside>
                )}

                {this.props.data.contactFooterImage && (
                    <figure className="col-12 mb-3">
                    <Text
                        text={this.props.data.contactFooterImage}
                        figureFormat={"box__sixteen_nine"}
                        container={false}
                    />
                    </figure>
                )}
                </div>
            </section>
      </Layout>
    )
  }
}

export default ContactUs

export const query = graphql`
  query ContactQuery {
    contacts: allContentfulContacts(
      sort: { fields: [order], order: ASC }
      filter: { node_locale: { eq: "en-US" }, showOnContactPage: { eq: true } }
    ) {
      edges {
        node {
          ...contactFragment
        }
      }
    }

    seoFields: contentfulSeoFields(slug: { eq: "contact-seo" }) {
      ...seoFieldsFragment
    }

    contactIntro: contentfulTexts(slug: { eq: "contact-intro" }) {
      ...textFragment
    }

    contactCareer: contentfulTexts(slug: { eq: "contact-career" }) {
      ...textFragment
    }

    contactFooterImage: contentfulTexts(slug: { eq: "contact-footer-image" }) {
      ...textFragment
    }

    offices: allContentfulOffices(
      sort: { fields: [order], order: ASC }
      filter: { node_locale: { eq: "en-US" }, venture: { eq: false } }
    ) {
      edges {
        node {
          ...officeFragmentBase
        }
      }
    }
  }
`
